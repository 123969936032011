<template>
  <!-- 动态发布 -->
  <div class="release">
    <nav-bar title="发布动态" left-arrow @click-left="$router.history.go(-1)" />
    <div class="wrap">
      <!-- 输入文字 -->
      <div class="text">
        <textarea
          name=""
          id=""
          cols="30"
          rows="10"
          placeholder="点击此处输入文字"
          maxlength="500"
          v-model="text"
        ></textarea>
        <div class="number">({{ text.length }}/500)</div>
      </div>
      <!-- 添加图片 -->
      <div class="add_img">
        <div class="title">添加图片</div>
        <div class="head_img">
          <div class="addimg">
            <div
              class="img-box"
              v-for="(item, index) in imgsback"
              :key="index"
              v-dragging="{ item, list: imgsback, index: index }"
            >
              <img class="img" :src="item" alt="" /><span @click="delimgback(i)"
                ><img src="@/assets/img/delete.png" alt=""
              /></span>
            </div>
            <div class="adatar">
              <img
                :src="avatar ? avatar : require('@/assets/img/add_img.png')"
                alt
              />
              <input
                type="file"
                name
                accept="image/jpeg, image/jpg, image/png"
                @change="fileChangeback($event)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="btn" @click="fabu"><span>发布</span></div>
    </div>
    <!-- 提示 -->
  </div>
</template>

<script>
import { NavBar } from "vant";
export default {
  components: {
    NavBar,
  },
  data() {
    return {
      headerName: "动态发布",
      msg: "", // 提示内容
      showAlert: false, // 提示显示
      ifload: false, // loading
      text: "", // 文字内容
      imgfilesback: [],
      imgsback: [],
      avatar: "",
      imgs: [],
    };
  },
  mounted() {
    // 图片拖动
    // this.$dragging.$on('dragged', ({ value }) => {
    //   this.imgsback = value.list;
    // })
  },
  methods: {
    onClickBack() {
      this.$router.history.go(-1)
      // console.log()
    },
    //上传图片
    fileChangeback(e) {
      var event = event || window.event;
      var file = event.target.files[0];
      var formData = new FormData();
      formData.append("file", file);
      let config = {
        headers: { "Content-Type": "multipart/form-data" }, //这里是重点，需要和后台沟通好请求头，Content-Type不一定是这个值
      };
      console.log(file);
      this.$postXForm("/common/upload", formData, (res) => {
        if (res.data.code == 1) {
          console.log(res.data.data.fullurl);
          this.imgsback.push(res.data.data.fullurl);
        } else {
          this.msg = res.data.msg;
          this.showAlert = true;
        }
      });
    },
    //删除图片的方法
    delimgback(i) {
      this.imgsback.splice(i, 1);
    },
    fabu() {
      this.imgs = [];
      for (let i = 0; i < this.imgsback.length; i++) {
        var url = "/uploads" + this.imgsback[i].split("/uploads")[1];
        this.imgs.push(url);
      }
      var imgs = this.imgs.join(",");
      this.$post(
        "/culture/dynamicRelease",
        { content: this.text, imgs },
        (res) => {
          if (res.data.code == 1) {
            this.msg = "发布成功";
            this.showAlert = true;
            setTimeout(() => {
              this.$router.push({ name: "dynamic", query: this.$route.query });
            }, 500);
          } else {
            this.msg = res.data.msg;
            this.showAlert = true;
          }
        }
      );
    },
  },
};
</script>

<style lang='scss' scoped>
.release {
  width: 100%;
    height: 100vh;
  .wrap {
    position: absolute;
    top: 50px;
    width: 100%;
    .text {
      position: relative;
      padding: 10px;
      padding-bottom: 0;
      textarea {
        width: 95%;
        padding: 10px;
        border: none;
        outline: none;
        resize: none;
        font-size: 14px;
        font-weight: bold;
        border-radius: 10px;
        font-family: "";
      }
      .number {
        position: absolute;
        right: 20px;
        bottom: 20px;
        color: #444444;
        font-size: 12px;
      }
    }
    .add_img {
      padding: 0 10px;
      .title {
        margin: 10px 0;
        font-size: 18px;
        span {
          font-size: 12px;
          color: #a0a0a0;
          margin-left: 5px;
        }
      }
      .addimg {
        display: flex;
        flex-wrap: wrap;
        background: white;
        border-radius: 10px;
        padding: 10px;
        .img-box {
          position: relative;
          width: 100px;
          height: 100px;
          margin-right: 10px;
          margin-bottom: 10px;
          .img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }
          span {
            position: absolute;
            right: -5px;
            top: -5px;
            z-index: 999;
            width: 20px;
            height: 20px;
            img {
              width: 100%;
            }
          }
        }
      }
      .adatar {
        position: relative;
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px dashed #bfbfbf;
        border-radius: 10px;
        img {
          width: 50%;
          height: 50%;
        }
        input {
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
        }
      }
    }
    .btn {
      margin-top: 100px;
      padding: 0 10px;
      display: flex;
      justify-content: center;
      span {
        width: 100%;
        height: 40px;
        background: #ab0404;
        color: white;
        color: #f2ecd4;
        font-size: 20px;
        text-align: center;
        line-height: 40px;
        border-radius: 10px;
      }
    }
  }
}
</style>